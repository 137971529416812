import styles from "./Desktop1200.module.css";
const Desktop1200 = () => {
  return (
    <div className={styles.desktop1200}>
      {/* <div className={styles.joinUsParent}>
         <div className={styles.joinUs}>10 Join Us</div>
        <div className={styles.frameParent}>
          <div className={styles.wereSeekingPassionateIndivParent}>
            <div className={styles.wereSeekingPassionate}>
              We’re seeking passionate individuals to join our team, ideally
              with a background in Embedded Systems Design, Machine Learning,
              Sensor Manufacturing and Prototyping.
            </div>
            <div className={styles.enterYourDetails}>
              Enter your details and we’ll get in touch with you
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.firstNameParent}>
                <div className={styles.firstName}>First Name</div>
                <div className={styles.frameChild} />
              </div>
              <div className={styles.firstNameParent}>
                <div className={styles.firstName}>Last Name</div>
                <div className={styles.frameChild} />
              </div>
              <div className={styles.firstNameParent}>
                <div className={styles.firstName}>Your Email</div>
                <div className={styles.frameChild} />
              </div>
              <div className={styles.firstNameParent}>
                <div className={styles.firstName}>Github</div>
                <div className={styles.frameChild} />
              </div>
            </div>
            <div className={styles.joinUsWrapper}>
              <div className={styles.joinUs1}>JOIN US</div>
            </div>
          </div>
          <img className={styles.lineIcon} alt="" src="/line-9.svg" />
        
        
        </div> 
      </div> */}
      <div className={styles.infomindfeedhealthParent}>
        <a
          className={styles.infomindfeedhealth}
          href="mailto:info@mindfeed.health"
          target="_blank"
        >
          info@mindfeed.health
        </a>
        <div className={styles.companyAe406fd657f74a61A82Parent}>
          <img
            className={styles.companyAe406fd657f74a61A82Icon}
            alt=""
            src="/company-ae406fd657f74a61a828b8f98b861a7c-047d5b67d8094127927b4c727ec02178-2@2x.png"
          />
          <div className={styles.mindfeed2023AllContainer}>
            <p className={styles.allRightsReserved}>© MindFeed 2023</p>
            <p className={styles.allRightsReserved}>ALL RIGHTS RESERVED</p>
            <p className={styles.allRightsReserved}>PRIVACY POLICY</p>
            <p className={styles.allRightsReserved}>TERMS OF USE</p>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv}>
        <div className={styles.rectangleWrapper}>
          <div className={styles.rectangleDiv} />
        </div>
        <div className={styles.buildingTheFutureOfNeuralParent}>
          <div className={styles.buildingTheFuture}>
            Building the future of neural interfaces
          </div>
          <div className={styles.decodingOurNervous}>
            Decoding our nervous system, one prototype at the time.
          </div>
        </div>
        <div className={styles.frameParent1}>
          <div className={styles.companyAe406fd657f74a61A82Group}>
            <img
              className={styles.companyAe406fd657f74a61A82Icon1}
              alt=""
              src="/company-ae406fd657f74a61a828b8f98b861a7c-047d5b67d8094127927b4c727ec02178-1@2x.png"
            />
            <b className={styles.mindfeed}>MindFeed</b>
          </div>
          {/* <div className={styles.companyParent}>
            <div className={styles.company}>01 Company</div>
            <div className={styles.company}>10 Join Us</div>
            </div> */}
        </div>
      </div>
      {/* <div className={styles.companyGroup}>
        <div className={styles.joinUs}>01 Company</div>
        <div className={styles.frameParent2}>
          <div className={styles.ourMissionsParent}>
            <div className={styles.ourMissions}>OUR MISSIONS</div>
            <div className={styles.frameParent3}>
              <div className={styles.decodeNerveSignalsAsSwiftlWrapper}>
                <div className={styles.decodeNerveSignals}>
                  Decode nerve signals as swiftly as our body does
                </div>
              </div>
              <div className={styles.createANaturalHumanMachineWrapper}>
                <div className={styles.decodeNerveSignals}>
                  Create a natural human-machine feedback experience
                </div>
              </div>
              <div className={styles.decodeMapAndConnectToOurWrapper}>
                <div className={styles.decodeNerveSignals}>
                  Decode, map and connect to our peripheral nervous system
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ourProductsParent}>
            <div className={styles.ourProducts}>OUR PRODUCTS</div>
            <div className={styles.frameParent4}>
              <div className={styles.weMakeTheSickestShitWrapper}>
                <div className={styles.decodeNerveSignals}>
                  Adaptable neural interfaces for mixed reality
                </div>
              </div>
              <div className={styles.rectangleParent}>
                <div className={styles.frameChild1} />
                <div className={styles.frameChild1} />
              </div>
              <div className={styles.weMakeTheSickestShitWrapper}>
                <div className={styles.decodeNerveSignals}>
                  Neural interfaces for muscle health monitoring in MSKDs
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Desktop1200;
