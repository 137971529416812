import styles from "./Desktop.module.css";
const Desktop = () => {
  return (
    <div className={styles.desktop}>
      <div className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        {/* <div className={styles.joinUs}>10 Join Us</div>
        <div className={styles.wereSeekingPassionateIndivParent}>
          <div className={styles.wereSeekingPassionate}>
            We’re seeking passionate individuals to join our team, ideally with
            a background in Embedded Systems Design, Machine Learning, Sensor
            Manufacturing and Prototyping.
          </div>
          <div className={styles.enterYourDetails}>
            Enter your details and we’ll get in touch with you
          </div>
          <div className={styles.frameParent}>
            <div className={styles.firstNameParent}>
              <div className={styles.firstName}>First Name</div>
              <div className={styles.div}>*</div>
              <div className={styles.frameItem} />
            </div>
            <div className={styles.firstNameParent}>
              <div className={styles.lastName}>Last Name</div>
              <div className={styles.div}>*</div>
              <div className={styles.frameItem} />
            </div>
            <div className={styles.firstNameParent}>
              <div className={styles.yourEmail}>Your Email</div>
              <div className={styles.div}>*</div>
              <div className={styles.frameItem} />
            </div>
            <div className={styles.githubParent}>
              <div className={styles.github}>Github</div>
              <div className={styles.frameChild1} />
            </div>
            <div className={styles.joinUsWrapper}>
              <div className={styles.joinUs1}>JOIN US</div>
            </div>
          </div>
          <div className={styles.frameChild2} />
        </div> */}

      </div>
      <div className={styles.mindfeed2023AllRightsReserParent}>
        <div className={styles.mindfeed2023AllContainer}>
          <p className={styles.allRightsReserved}>© MindFeed 2023</p>
          <p className={styles.allRightsReserved}>ALL RIGHTS RESERVED</p>
          <p className={styles.allRightsReserved}>PRIVACY POLICY</p>
          <p className={styles.allRightsReserved}>TERMS OF USE</p>
        </div>
        <a
          className={styles.infomindfeedhealth}
          href="mailto:info@mindfeed.health"
          target="_blank"
        >
          info@mindfeed.health
        </a>
        <img
          className={styles.companyAe406fd657f74a61A82Icon}
          alt=""
          src="/company-ae406fd657f74a61a828b8f98b861a7c-047d5b67d8094127927b4c727ec02178-21@2x.png"
        />
      </div>
      <div className={styles.frameGroup}>
        {/* <div className={styles.rectangleWrapper}>
          <div className={styles.rectangleDiv} />
        </div> */}
        <div className={styles.companyParent}>
          <div className={styles.company}>01 Company</div>
          <div className={styles.company}>10 Join Us</div>
        </div>
        <div className={styles.buildingTheFuture}>
          Building the future of neural interfaces
        </div>
        <div className={styles.decodingOurNervous}>
          Decoding our nervous system, one prototype at the time.
        </div>
        <div className={styles.companyAe406fd657f74a61A82Parent}>
          <img
            className={styles.companyAe406fd657f74a61A82Icon1}
            alt=""
            src="/company-ae406fd657f74a61a828b8f98b861a7c-047d5b67d8094127927b4c727ec02178-11@2x.png"
          />
          <b className={styles.mindfeed}>MindFeed</b>
        </div>
      </div>
      {/* <div className={styles.ourMissionsParent}>
        <div className={styles.ourMissions}>OUR MISSIONS</div>
        <div className={styles.company1}>01 Company</div>
        <div className={styles.ourProducts}>OUR PRODUCTS</div>
        <div className={styles.frameContainer}>
          <div className={styles.decodeNerveSignalsAsSwiftlWrapper}>
            <div className={styles.decodeNerveSignals}>
              Decode nerve signals as swiftly as our body does
            </div>
          </div>
          <div className={styles.createANaturalHumanMachineWrapper}>
            <div className={styles.createANatural}>
              Create a natural human-machine feedback experience
            </div>
          </div>
          <div className={styles.decodeMapAndConnectToOurWrapper}>
            <div className={styles.decodeMapAnd}>
              Decode, map and connect to our peripheral nervous system
            </div>
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.weMakeTheSickestShitWrapper}>
            <div className={styles.weMakeThe}>We MAKE THE SICKEST SHIT</div>
          </div>
          <div className={styles.rectangleGroup}>
            <div className={styles.frameChild3} />
            <div className={styles.frameChild3} />
          </div>
          <div className={styles.decodeMapAndConnectToOurContainer}>
            <div className={styles.weMakeThe}>
              Decode, map and connect to our peripheral nervous system
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Desktop;
